<!-- The ref attr used to find the swiper instance -->
<template>
  <swiper :options="swiperOption" ref="mySwiper">
    <swiper-slide class="first-slide-box">
      <div class="contaier">
        <img src="@/assets/images/Logo_01.png" alt="" class="logo" />
        <div class="text common-text">
          Download LiberLive App
          <div>（For Android）</div>
        </div>
        <div class="slide-box">
          <!-- <img src="@/assets/images/btn_slide.png" alt="" class="img" /> -->
          <img src="@/assets/images/btn_circle.png" alt="" class="img" />
        </div>
        <div class="common-text little">Drag upwards</div>
      </div>
    </swiper-slide>
    <swiper-slide class="second-slide-box">
      <div class="title">
        <img src="@/assets/images/Logo_01.png" alt="" class="logo" />
        <div class="text">
          Download LiberLive App
          <div>（For Android）</div>
        </div>
      </div>
      <div class="step-contaier">
        <div class="step-one">
          <div class="step-left"><span>STEP </span> &nbsp;<span>01</span></div>
          <div class="step-right">Enable the permission to install apps</div>
        </div>
        <div class="inner-swiper-box">
          <div class="tabs-guidances">
            <div
              class="tab-item"
              v-for="(item, index) in androidSwiArr"
              :key="index"
              @click="tabClick(item)"
            >
              <div
                class="name"
                :class="activeIndex === item.name ? 'is-active' : 'not-active'"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="all-inner">
            <swiper :options="innerSwiperOption" ref="step-swiper">
              <swiper-slide
                v-for="(innerItem, index) in activeInnerImages"
                :key="index + '2'"
                class="inner-swiper"
              >
                <img
                  :src="innerItem.img"
                  alt=""
                  class="inner-img"
                  :class="`active-${index}`"
                />
              </swiper-slide>
              <div class="inner-swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
        <div class="guides-desc">
          <span class="rit" style="color: #949494"
            >Guidance:{{ activeGuidanceText }}</span
          >
        </div>
        <div class="no-model">Didn't find your phone？</div>

        <div class="step-two">
          <div class="step-left"><span>STEP </span>&nbsp; <span>02</span></div>
          <div class="step-right">Tap to download the APK</div>
        </div>
        <div class="step-three">
          <div class="down-btn">
            <el-button class="btn" type="primary" @click="handleDownApp()">
              <img src="@/assets/images/download.png" alt="" class="icon-btn" />
              DOWNLOAD
            </el-button>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        direction: "vertical", // 设置为垂直轮播
        spaceBetween: 0, // slide之间的间距
        pagination: {
          el: ".swiper-pagination",
        },
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        height: window.innerHeight, // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        speed: 300,
        // autoplay: {
        //   //   delay: 3000,
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      innerSwiperOption: {
        pagination: {
          el: ".inner-swiper-pagination",
        },
        direction: "horizontal", //水平方向移动
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        // resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        initialSlide: 0,
      },
      androidSwiArr: [
        {
          name: "Samsung",
          swiperData: [
            {
              id: "01",
              img: require("@/assets/images/guidances/Android_Samsung_01.png"),
            },
            {
              id: "02",
              img: require("@/assets/images/guidances/Android_Samsung_02.png"),
            },
            {
              id: "03",
              img: require("@/assets/images/guidances/Android_Samsung_03.png"),
            },
            {
              id: "04",
              img: require("@/assets/images/guidances/Android_Samsung_04.png"),
            },
          ],
          guidance:
            "「Settings」-「Safety & emergency」 - 「More security settings」- 「Install unknown apps」",
        },
        {
          name: "Google",
          swiperData: [
            {
              id: "01",
              img: require("@/assets/images/guidances/Android_Google_01.png"),
            },
            {
              id: "02",
              img: require("@/assets/images/guidances/Android_Google_02.png"),
            },
            {
              id: "03",
              img: require("@/assets/images/guidances/Android_Google_03.png"),
            },
            {
              id: "04",
              img: require("@/assets/images/guidances/Android_Google_04.png"),
            },
          ],
          guidance:
            "「Settings」-「Apps」 - 「Special app access」- 「Install unknown apps」",
        },
        {
          name: "One Plus",
          swiperData: [
            {
              id: "01",
              img: require("@/assets/images/guidances/Android_OnePlus_01.png"),
            },
            {
              id: "02",
              img: require("@/assets/images/guidances/Android_OnePlus_02.png"),
            },
            {
              id: "03",
              img: require("@/assets/images/guidances/Android_OnePlus_03.png"),
            },
            {
              id: "04",
              img: require("@/assets/images/guidances/Android_OnePlus_04.png"),
            },
          ],
          guidance: "147637647836846384683",
        },
      ],
      activeIndex: "Samsung",
    };
  },
  mounted() {},
  methods: {
    tabClick(item) {
      console.log(item);
      this.activeIndex = item.name;
    },
    handleDownApp() {
      window.location.href =
        "https://qiniu.public.liberlive-music.com/versionApp/LiberLive_release_256_7.apk";
    },
  },
  computed: {
    activeGuidanceText() {
      return this.androidSwiArr.find((item) => item.name === this.activeIndex)
        .guidance;
    },
    activeInnerImages() {
      return this.androidSwiArr.find((item) => item.name === this.activeIndex)
        .swiperData;
    },
  },
};
</script>
<style scope lang="scss">
@keyframes upAndDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-0.2rem);
    opacity: 0.75;
  }
  25% {
    transform: translateY(-0.4rem);
    opacity: 0.5;
  }
  75% {
    transform: translateY(-0.8rem);
    opacity: 0.25;
  }
  100% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}
.swiper-slide {
  width: 7.5rem;
  height: 100vh;
}
.swiper-wrapper {
}
.first-slide-box {
  width: 7.5rem !important;
  height: 100vh !important;
  background: url("@/assets/images/frame_bg.png") no-repeat,
    linear-gradient(360deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-size: cover;
  position: relative;
  .contaier {
    // padding-top: 8.28rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    .logo {
      width: 5.4rem;
      height: auto;
    }
    .common-text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.32rem;
      color: rgba(255, 255, 255, 0.6);
      line-height: 0.38rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      &.little {
        margin-top: 0.32rem;
      }
    }
    .text {
      //   max-width: 3.7rem;
      margin-top: 0.12rem;
      margin-bottom: 1.4rem;
    }
    .slide-box {
      width: 1.23rem;
      height: 2.09rem;
      background: url("@/assets/images/btn_slide.png") no-repeat center center;
      background-size: cover;
      position: relative;
      img {
        height: 0.88rem;
        width: auto;
        position: absolute;
        bottom: 0.16rem;
        left: 0.19rem;
        animation: upAndDown 1.2s infinite linear;
      }
    }
  }
}
.second-slide-box {
  width: 7.5rem !important;
  height: 100vh !important;
  //   background: url("@/assets/images/frame_bg.png") no-repeat,
  //     linear-gradient(360deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  //   background-size: 100% auto;
  background: #181818;
  backdrop-filter: blur(0.16rem);
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.7rem;
    .logo {
      display: none;
      width: 5.4rem;
      height: auto;
    }
    .text {
      //   max-width: 3.2rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.32rem;
      color: rgba(255, 255, 255, 0.6);
      line-height: 0.4rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 0.36rem;
    }
  }
  .step-contaier {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .step-one,
    .step-two {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.28rem;
      .step-left {
        width: 1.64rem;
        height: 0.56rem;
        background: #050505;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 0.28rem;
        color: #ffffff;
        line-height: 0.32rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
      .step-right {
        width: 3.94rem;
        height: 0.56rem;
        background: rgba(255, 255, 255, 0.6);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.2rem;
        color: #191919;
        line-height: 0.24rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }
    .step-two {
      .step-left {
      }
      .step-right {
      }
    }
    .step-three {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .btn {
        width: 5.58rem;
        height: 0.96rem;
        background: #324fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: none;
        &:hover,
        &:active,
        &:focus {
          opacity: 0.85;
        }
        .icon-btn {
          display: block;
          width: 0.32rem;
          height: 0.32rem;
          margin-right: 0.16rem;
        }
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 0.28rem;
          color: #ffffff;
          line-height: 0.32rem;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
    .inner-swiper-box {
      display: flex;
      flex-direction: column;
      width: 5.9rem;
      height: 6.72rem;
      background: #272626;
      padding: 0.24rem 0.32rem 0;
      .tabs-guidances {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 0.64rem;
        .tab-item {
          width: 1.76rem;
          height: 0.64rem;
          .name {
            width: 1.76rem;
            height: 0.64rem;
            background: #090909;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 0.24rem;
            color: #ffffff;
            line-height: 0.28rem;
            text-align: center;
            font-style: normal;
            text-transform: none;
            &.is-active {
              background: #6d82ff;
            }
            &.not-active {
              width: 1.76rem;
              height: 0.64rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 0.24rem;
              color: rgba(255, 255, 255, 0.6);
              line-height: 0.28rem;
              text-align: center;
              font-style: normal;
              text-transform: none;
            }
          }
        }
      }
      .inner-swiper {
        margin-top: 0.24rem;
        // width: 5.26rem !important;
        height: 5.28rem;
      }
      .inner-swiper-pagination {
        .swiper-pagination-bullet {
          width: 0.08rem;
          height: 0.04rem;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 0rem 0rem 0rem 0rem;
          &.swiper-pagination-bullet-active {
            width: 0.32rem;
            background: #fff;
          }
        }
      }
    }
    .all-inner {
      .swiper-container {
        width: 5.26rem;
        position: relative;
        height: 6rem;

        .swiper-wrapper {
        }
        .inner-swiper-pagination {
          position: absolute;
          bottom: -0.2rem;
          z-index: 10;
          left: 2.14rem;
        }
      }
    }
    .guides-desc {
      max-width: 6.2rem;
      margin-bottom: 0.28rem;
      margin-top: 0.24rem;
      .lef {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.24rem;
        color: #ffffff;
        line-height: 0.24rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
      .rit {
        display: flex;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.24rem;
        color: #ffffff;
        line-height: 0.24rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }
    .no-model {
      display: flex;
      align-items: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.24rem;
      color: rgba(255, 255, 255, 0.3);
      line-height: 0.28rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 0.48rem;
    }
    .inner-img {
      display: block;
      width: 5.26rem;
      height: 5.28rem;
    }
  }
}
</style>
